<template>
    <div class="container margin">
        <!-- <div id="ValassisGallery" style="width: 100%" data-getconsumerdata="getConsumerData"></div> -->
        <!-- <div id="ValassisGallery" style="width: 100%;" data-loaded="valassisLoaded" data-lang="es" datagetconsumerdata="getConsumerData" data-synsrc="evNkGTjK264%3D" data-SyndicationName="es_valesycupones_new_syc" data-ApplicationName="es_affiliate_own" data-CouponGalleries="es_affiliate_valesycupones_gal"></div> -->
        <!-- <div id="ValassisGallery" style="width: 100%;" data-loaded="valassisLoaded" data-lang="es" datagetconsumerdata="getConsumerData" data-synsrc="evNkGTjK264%3D" ></div> -->
        <div id="ValassisGallery" style="width: 100%;" data-loaded="valassisLoaded" data-lang="es" data-getconsumerdata="getConsumerData" data-synsrc="evNkGTjK264%3D" ></div>

        <MyLoading v-if="isLoading" />
        <transition>
          <div class="modal-overlay-login d-none"></div>
        </transition>
      
        <transition name="pop" appear>
          <div class="modal-vue-login d-none" id="modal-login" role="dialog">
            <div class="d-flex justify-content-end px-3 pt-3">
              <button type="button" class="btn-close"  @click="showModal()"></button>
            </div>
            <UserLogin/>
          </div>
        </transition>
    </div>
</template>
<script>
import MyLoading from '@/components/Loading.vue';
import UserLogin from '@/views/Users/UserLogin.vue';

export default {
    name : 'MyCoupons',
    components :{
      MyLoading,
      UserLogin
    },
    data() {
      return {
        isLoading: false,
      }
    },
    beforeCreate() {
      this.isLoading = true
      //let coupons = document.createElement('script')
      let savy = document.createElement('script')
      //coupons.setAttribute('src', 'https://coupons.valassis.eu/scripts/core/util/init.js')
      savy.setAttribute('src', 'https://coupons.valesycupones.es/scripts/core/util/init.js')
      document.head.appendChild(savy)
      //document.head.appendChild(coupons)
    }, 
    methods: {
      showModal: function(){
        document.getElementById('modal-login').classList.add('d-none')
        document.querySelector('.modal-overlay-login').classList.add('d-none')
      }
    },

}


</script>

<style scoped>

  .button {
    border: none;
    color: #FFF;
    background: #42b983;
    appearance: none;
    font: inherit;
    font-size: 1.8rem;
    padding: .5em 1em;
    border-radius: .3em;
    cursor: pointer;
  }
  .cursor {
    cursor: pointer;
  }
  
  .modal-vue-login {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    height: fit-content;
    max-width: 22rem;
    border-radius: 1rem;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    background: #FFF;
    z-index: 9999999999999999999;
    transform: none;
  }
  .modal-vue-login h1 {
    margin: 0 0 1rem;
  }
  
  .modal-overlay-login {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
  }
  
  /* ---------------------------------- */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .4s linear;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  
  .pop-enter-active,
  .pop-leave-active {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
  }
  
  .pop-enter,
  .pop-leave-to {
    opacity: 0;
    transform: scale(0.3) translateY(-50%);
  }
</style>