<template>
<div class="container margin">
  <div v-show="seen" class="alert alert-warning alert-dismissible fade show" role="alert">
    <strong>{{message}}</strong>
    <button type="button" class="btn-close" v-on:click="seen = !seen"></button>
  </div>

  <div class="row d-flex justify-content-center">
      <div class="col-md-7">
          <div class="card px-5 py-4" id="">
              <div class="form-data" >
                  <div class="text-center mb-4">
                      <h4>Regístrate</h4>
                  </div>
                  <div class="forms-inputs mb-4">
                    <span>Photo</span> 
                    <input type="file" v-bind:class="{'form-control':true}" @change="handlePhotoUpload" />
                  </div>
                  <div class="forms-inputs mb-4"> <span>Email</span> <input type="text" v-model="email" v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email) && emailBlured}" v-on:blur="emailBlured = true">
                    <div class="invalid-feedback">El campo es requerido.</div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Username</span> <input type="text" v-model="username" v-bind:class="{'form-control':true, 'is-invalid' : !validUsername(username) && usernameBlured}" v-on:blur="usernameBlured = true">
                      <div class="invalid-feedback">El campo es requerido.</div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Nombres</span> <input type="text" v-model="nombres" class="form-control">
                      <div class="invalid-feedback">El campo es requerido.</div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Apellidos</span> <input type="text" v-model="apellidos" class="form-control">
                      <div class="invalid-feedback">El campo es requerido.</div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Contraseña</span> <input type="password" v-model="password" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(password) && passwordBlured}" v-on:blur="passwordBlured = true">
                      <div class="invalid-feedback">
                        <ul>
                          <li>El campo Requerido.</li>
                          <li>Mínimo 8 caracteres maximo 15.</li>
                          <li>Debe de tener al menos una letra en minúscula.</li>
                          <li>Debe de tener al menos una letra en mayúscula.</li>
                          <li>Debe de tener al menos un número.</li>
                          <li>Debe de tener al menos un caracteres especiales (!@#$%^&*).</li>
                        </ul>
                      </div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Repita la Contraseña</span> <input type="password" v-model.trim="repeatPassword" v-bind:class="{'form-control':true, 'is-invalid' : !validRepeatPassword(repeatPassword) && passwordBlured}" v-on:blur="repeatPasswordBlured = true">
                    <div class="invalid-feedback">Las Contraseñas deben de ser iguales.</div>
                  </div>
                  <div class="form-check">
                    <input v-model="checkbox" type="checkbox" class="form-check-input" id="pp" >
                    <a :href="pp"  class="form-check-label border-0" v-bind:class="{'form-control':true, 'is-invalid' : !validCheckbox(checkbox) && checkboxBlured}" v-on:blur="checkboxBlured = true">
                      Política de Privacidad
                    </a >
                  </div>
                  <div class="mb-3"> <button v-on:click.stop.prevent="submit" class="btns btn w-100">Registrar</button> </div>
              </div>
          </div>
      </div>
  </div>
  <MyLoading v-if="isLoading" />
</div>
    
</template>

<script>
import axios from 'axios';
import MyLoading from '@/components/Loading.vue';
export default {
  name: 'UserRegister',
  components: {
    MyLoading,
  },

  data: function () {
    return {
      email : "",
      emailBlured : false,
      valid : false,
      submitted : false,
      password:"",
      passwordBlured:false,
      username:"",
      usernameBlured:false,
      message: '',
      nombres: '',
      apellidos: '',
      isLoading: false,
      repeatPassword: '',
      repeatPasswordBlured: 'false',
      seen: false,
      photo : null,
      checkbox : false, 
      checkboxBlured : false,
      pp : 'documents/POLITICA_DE_PRIVACIDAD_SAVI_1.pdf',
    }
  },

  methods:{

    async handlePhotoUpload(event) {
      this.photo = event.target.files[0];
      console.log(this.photo)
    },

    validate : function(){
      this.emailBlured = true;
      this.passwordBlured = true;
      this.usernameBlured = true;
      this.repeatPasswordBlured = true;
      this.checkboxBlured = true;
      if( this.validEmail(this.email) && this.validPassword(this.password) && this.validUsername(this.username) && this.validRepeatPassword(this.repeatPassword) && this.validCheckbox(this.checkbox)){
        this.valid = true;
      }
    },

    Register() {
      this.message = '';
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("first_name", this.nombres);
      formData.append("last_name", this.apellidos);
      formData.append("email", this.email);
      formData.append("photo", this.photo);
      formData.append("password", this.password);
      console.log(formData);
      axios.post(`wp/v2/register/users`, formData, {
        headers :{
          "Content-Type":"multipart/form-data",
          "Accept" : "*/*"
        }
      })
      .then((response) => {
        // if redirected to login from secured page, redirect back
        this.$router.push('Login');
        console.log(response);
        
      })
      .catch((error) => {
        console.log(error)
        this.message = error.response.data.message;
        this.isLoading = false;  
        this.seen = true;
      });
    },

    validEmail : function(email) {
      let valitateEmail = /(.+)@(.+){2,}\.(.+){2,}/;
        if(valitateEmail.test(email.toLowerCase())){
        return true;
      }
    },

    validCheckbox : function(checkbox) {
      console.log(checkbox)

      if (checkbox) {
        return true
      }
    },

    validPassword : function(password) {
      let pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
      if (pattern.test(password)) {
        return true;
      }
    },

    validRepeatPassword : function(repeatPassword) { 
      if(repeatPassword == this.password){
          return true;
      }
    },

    validUsername : function(username) {
      if (username.length > 0 ) {
        return true;
      }
    },

    submit : function() {
      this.validate();
      if(this.valid) {
        this.submitted = true;
        this.Register();
      }
    }
  }
};
</script>

<style lang="scss">
  @import '@/scss/form';

  .userRegister {
    margin-top: 4rem;
  }
</style>
