<template>
    <div class="">
        <Post/>
    </div>
</template>

<script>
import Post from "@/components/Post";
export default {
    name: 'SinglePost',
    components : {
        Post
    }
}
</script>