<template>
    <div>
        <nav class="site-nav js-site-nav ">
            <div class="overlay" v-if="overlay" @click="overlay= false, isActive=!isActive"></div>
            <div class="wrapper wrapper--nav-area">
                <div class="d-none d-lg-block">
                    <ul class="d-flex justify-content-center gap-5 mb-0 list-inline align-items-center">
                        <li class="list-inline-item">
                            <router-link to="/inicio" @click="isActive=!isActive">
                                Inicio
                            </router-link>
                        </li>
                        <li class="list-inline-item">
                            <router-link to="/entradas" @click="isActive=!isActive">
                                Blog  
                            </router-link>
                        </li>
                    </ul>
                </div>

                <div id="mySidenav" class="sidenav" :class="{openNavClass: isActive}">
                    <template v-if="$store.state.isLoggedIn">
                        <div>
                            <div class="user-sidebar">
                                <div v-if="avatar != ''">
                                    <img :src="avatar" class="rounded-circle object-fit-cover" width="60px" height="60px" alt="">
                                </div>
                                <div v-else>
                                    <img src="@/assets/home/usuario_r.png" class="rounded-circle object-fit-cover" width="40px" height="40px" alt="">
                                </div>
                                <h3>{{ name }} {{lastName }}</h3>
                            </div>
                        </div>
                    </template>
                    <ul class="site-nav__menu u-list-reset js-menu"  id="menu">
                        <li>
                            <router-link to="/inicio" @click.native="isActive=false, overlay=false">
                                <span class="description-link">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 30px;" class="">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                        </svg>
                                        Inicio
                                    </div>
                                    <img src="@/assets/menu/arrow.svg" alt="">
                                </span>
                            </router-link>
                        </li>
                        <template v-if="$store.state.isLoggedIn">
                            <li>
                                <router-link to="/perfil" @click.native="isActive=false, overlay=false">
                                    <span class="description-link">
                                        <div>
                                            <img src="@/assets/menu/engranajes.svg" alt="">
                                            Perfil
                                        </div>
                                        <img src="@/assets/menu/arrow.svg" alt="">
                                    </span>

                                </router-link>
                            </li>
                        </template>
                        <li>
                            <router-link to="/entradas" @click.native="isActive=false, overlay=false">
                                <span class="description-link">
                                    <div>
                                        <img src="@/assets/menu/blog.svg" alt="">
                                        Blog
                                    </div>
                                    <img src="@/assets/menu/arrow.svg" alt="">
                                </span>
                            </router-link>
                        </li>
                        <!-- <li>
                            <router-link to="/cupones"  @click.native="isActive=false, overlay=false">
                                <span class="description-link">
                                    <div>
                                        <img src="@/assets/menu/euro.svg" alt="">
                                        Cupones
                                    </div>
                                    <img src="@/assets/menu/arrow.svg" alt="">
                                </span>
                            </router-link>
                        </li> -->
                        <template v-if="$store.state.isLoggedIn">
                            <li>
                                <a href="logout"  @click.prevent="logout()">
                                    <span class="description-link">
                                        <div>
                                            <img src="@/assets/menu/cerrar.svg" alt="">
                                            Cerrar sesión
                                        </div>
                                        <img src="@/assets/menu/arrow.svg" alt="">
                                    </span>
                                </a>
                            </li>
                        </template>
                    </ul>
                    <div class="hidden-sidebar">
                        <span @click="isActive=false, overlay=false"> 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-25 h-25">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                            </svg>

                        </span>
                    </div>
                </div>

                <button  class="menu-button js-menu-button" @click="isActive=!isActive, overlay=!overlay">
                    <span class="menu-button__lines">
                        <span class="menu-button__line"></span>
                        <span class="menu-button__line"></span>
                        <span class="menu-button__line"></span>
                    </span>
                </button>

                <div class="logo ">
                <router-link to="/"  class="d-flex justify-content-center">
                    <img src="@/assets/logo.png" class="w-50" alt="" srcset="">
                </router-link>
                </div>
                <div class="users">
                    <div class="">
                        <button class="dropdown-toggle btn p-0 d-flex justify-content-center w-100 text-white  my-0 m border-0 display-6 d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false">
                            <template v-if="!$store.state.isLoggedIn">
                                <svg xmlns="http://www.w3.org/2000/svg" :style="{width: fontSize}" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </template>
                            <template v-else>
                                <div v-if="avatar != ''">
                                    <img :src="avatar" class="rounded-circle object-fit-cover" width="60px" height="60px" alt="">
                                </div>
                                <div v-else>
                                    <img src="@/assets/home/usuario_r.png" class="rounded-circle object-fit-cover" width="30px" height="30px" alt="">
                                </div>
                            </template>
                        </button>
                        <ul class="dropdown-menu">
                            <template v-if="$store.state.isLoggedIn">
                                <li><router-link to="/perfil" class="dropdown-item" @click.native="isActive=false, overlay=false">Perfil</router-link></li>
                                <li><a class="dropdown-item" href="#logout" @click.prevent="logout()">Cerrar Sesión</a></li>
                            </template>
                            <template v-else>
                                <li><router-link class="dropdown-item" to="/login" @click.native="isActive=false,overlay=false">Login</router-link></li>
                                <li><router-link class="dropdown-item"  to="/register" @click.native="isActive=false,overlay=false">Regístrate</router-link></li>
                            </template>
                        </ul>
                    </div>

                </div>
            </div>
        </nav>
        <MyLoading v-if="isLoading" />
    </div>
    
    
    
</template>

<script>
import store from '@/store';
import MyLoading from '@/components/Loading.vue';
export default {
    name: 'MySidebar',
    components : {
        MyLoading
    },
    data() {
        return {
            isActive: false,
            isLoading: false,
            fontSize: '1.5rem',
            avatar : store.state.avatar,
            name: store.state.firstName,
            lastName: store.state.lastName,
            overlay :false
        };
    },
    methods : {
        async logout() {
            this.isLoading = true
            await this.$store.dispatch('logout');
            this.$router.push({ name: 'UserLogin' });
            this.isLoading = false
            this.isActive = false
            this.overlay=false
        },
    }
};
</script>

<style lang="scss">
    /*
        Utils, helpers
    */

    .u-list-reset {
        list-style: none;
        padding-left: 0;
        margin: 0;

        li {
            position: relative;
            padding: 10px 0;

            &::after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: #4E4E4E;
                position: absolute;
            }
        }
    }

    /*
    Menu button
    */

    .menu-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        cursor: pointer;
        border: none;
        outline: none;
        padding: .5rem 0 .5rem 2rem;
        color: var(--lightest);
        position: relative;
        left: 0;
        top: 0;
        justify-self: start;
        grid-area: menuButton;
    }

    .menu-button:active {
        transform: translateY(-.2rem);
    }

    @media (min-width: 900px) {
        .menu-button {
            display: none;
        }
    }

    .menu-button__lines {
        display: grid;
        gap: 5px;
        transition: all .2s ease;
        position: absolute;
        left: 0;
    }

    .menu-button__lines--open {
        gap: 0;
    }

    .menu-button__line {
        display: block;
        transition: .2s ease;
        content: '';
        width: 1.5rem;
        height: 2px;
        background-color: white;
        transform-origin: center;
        border-radius: 25px;
    }

    .menu-button:hover .menu-button__lines {
        gap: 5px;
    }

    .menu-button__lines--open .menu-button__line {
        grid-row-start: 1;
        grid-column-start: 1;
        transform: rotate(45deg) scale(1.6);
    }

    .menu-button__lines--open .menu-button__line:nth-child(3) {
        transform: rotate(-45deg) scale(1.6);
    }

    .btn{
        height: none !important;
        background-color: transparent !important;
        color: white;
    }

    /*
    Wrapper
    */

    .wrapper {
        max-width: var(--lg);
        margin: 0 auto;
        padding: 0 1.5rem;
        z-index: 99999;
    }

    .wrapper--intro {
        margin: 12rem auto;
        text-align: center;
    }


    .wrapper--nav-area {
    display: grid;
    width: 100%;
    align-items: center;
    grid-template-areas:
    " menuButton logo users "
    "menu menu menu";
    grid-template-columns: 20% 60% 20%;

    }

    @media (max-width: 900px) {
        .wrapper--nav-area {
            grid-template-rows: auto 1fr;
        }

        .site-nav--is-open .wrapper--nav-area{
            height: calc(100vh - 1rem);

        }
    }

    @media (min-width: 62.5rem) {

        .wrapper--nav-area {
            grid-template-areas: "logo menu users";
        }
    }

    /*
    Logo
    */

    .logo {
        font-size: 2rem;
        justify-self: center;
        grid-area: logo;
        position: relative;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;

        span {
            color: var(--greenlight);
        }
    }

    /*
    Site nav
    */

    .site-nav {
        position: sticky;
        top: 0;
        left: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
        z-index: 3;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
        background: var(--green);

        .overlay {
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .5);
        }
    }

    .site-nav__menu {
        grid-area: menu;
    }

    .site-nav__link {
        padding: 1rem;
        display: block;
    }

    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        background: var(--green);
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }
    .openNavClass {
        width:250px;
        position:fixed;
    }
    .user-sidebar {
        padding: 1rem;
        color: white;
        display: flex;
        flex-direction: column;

        h3 {
            margin-top: 0.5rem;
            font-size: 20px;
            font-weight: bold;
        }
    }
  
    .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        color: white;
        display: block;
        transition: 0.3s;
        font-size: 14px;
        font-weight: bold;
    }
    
    .sidenav a:hover {
        color: #f1f1f1;
    }
    
    .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }
    
    #main {
        transition: margin-left .5s;
        padding: 16px;
        
    }
    
    @media screen and (max-height: 450px) {
        .sidenav {padding-top: 15px;}
        .sidenav a {font-size: 18px;}
    }

    .description-link {
        display: flex;
        justify-content: space-between;
        margin: auto 20px;
    }

    .hidden-sidebar {
        position: absolute;
        bottom: 50px;
        padding: 1rem;

        span {
            color: white;
        }
    }
</style>

  