<template>
  <div>
    <div class="modal-vue" v-if="isModalFather">
     <!-- overlay -->
      <div class="overlay" @click="isModalFather = false"></div>
     
     <!-- modal -->
      <div class="modal-primary rounded overflow-hidden py-2 px-3 mx-3">
        <div class="d-flex justify-content-end align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" @click="isModalFather = false" :style="{width: width}" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div class="p-3 text-center">
          <div :class="{fadeIn: isModalVisible, fadeOut:!isModalVisible}"  v-if="isModalVisible">
            <img src="@/assets/home/Modal1.png" alt="">
            <h2 class=" fw-bolder mb-0">¡Regístrate y</h2>
            <h2 class=" fw-bolder mt-0">empieza ahorrar!</h2>
            <p :style="{color: primary}">
              Para empezar necesitarás crear una cuenta.
              Haz clic en el boton <span class="fw-bolder" :style="{color: secundary}">"Registrarse"</span>
              y te guiaremos atraves del proceso.
            </p>
            <a class="btn-border rounded-pill w-full"  @click="isModalVisibleTwo=true, isModalVisible=false">Siguiente</a>
          </div>
          <div :class="{fadeIn: isModalVisibleTwo,  fadeOut:!isModalVisibleTwo}" v-if="isModalVisibleTwo">
            <img src="@/assets/home/Modal2.png" alt="">
            <h2 class="my-2 fw-bolder">Selecciona las promociones</h2>
            <p :style="{color: secundary}" class="fw-bolder mb-0"> Selecciona tu descuento y comienza a ahorrar.</p>
            <p class="mt-0" :style="{color: primary}">
              Por favor ten en cuenta que en algunas promociones puedes ser redirigidos a otras páginas.
            </p>
            <a class="btn-border rounded-pill w-full" @click="isModalVisibleTree = true, isModalVisibleTwo= false"> Siguiente</a>
          </div>
          <div :class="{fadeIn: isModalVisibleTree , fadeOut: !isModalVisibleTree}" v-if="isModalVisibleTree">
            <img src="@/assets/home/Modal4.png" alt="">
            <h2 class="my-2 fw-bolder">Disfruta ahorrando cada día</h2>
            <p :style="{color: primary}">

              <span  class="fw-bolder" :style="{color: secundary}" > Canjea tus promociones y empieza a ahorrar</span>
              en tu cesta de la compra desde ahora.
            </p>
            <a class="btn-border rounded-pill w-full" @click="isModalFather = false">Empezar</a>
          </div>
        </div>
        <div class="dots">
          <div :class="{ active: isModalVisible }" @click="isModalVisible=true, isModalVisibleTwo=false, isModalVisibleTree = false"></div>
          <div :class="{ active: isModalVisibleTwo }" @click="isModalVisible=false, isModalVisibleTwo=true, isModalVisibleTree = false"></div>
          <div :class="{ active: isModalVisibleTree }" @click="isModalVisible=false, isModalVisibleTwo=false, isModalVisibleTree = true"></div>

        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>

  export default {
    name : 'MyModal',
    components : {

    },
    data() {
      return {
        fontSize : '1.5rem',
        width: '20px',
        primary : '#224B50',
        secundary : '#00CCCC',
        isModalVisibleTwo : false,
        isModalVisibleTree : false,
        isModalVisible : true,
        isModalFather : true

      };
    },
  };
  </script>
  
  <style lang="scss">

  .dots {
    display: flex;
    justify-content:space-around;
    margin-bottom: 1rem;

    div {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid #224B50;
      background-color: #efefef;
    }

    .active {
      background-color: #224B50;
      border: none !important;
    }
  }

  .fadeIn {
    animation: fadeIn 0.8s;
  }

  .fadeOut {
    animation: fadeOut 0.8s;
  }

  .btn-border {
    color: #224B50;
    text-align: center;
    border : 1px solid #224B50;
    background-color: white !important;
    width: 100%;
    animation: fadeIn 0.8s;
    padding: 0.375rem  0.75rem;
    margin-top: 1rem;
    display: block;
  }
  
  </style>