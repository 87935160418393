import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

import MyHome from './views/Home.vue';
import UserLogin from './views/Users/UserLogin.vue';
import UserRegister from './views/Users/UserRegister.vue';
import UserPerfil from './views/Users/UserPerfil.vue';
import MyRetrieve from './views/Users/Retrieve.vue';
import resetPassword from './views/Users/ResetPassword.vue';
import MyPosts from './views/Posts/Posts.vue';
import SinglePost from './views/Posts/SinglePost.vue';
import Prueba from './views/Users/MyRegisterPrueba.vue'
import MyStart from './views/StartView.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MyHome,
    meta: {
      title: 'Home',
      noAuthRequired: true,
    },
  },
  {
    path: '/inicio',
    name: 'MyStart',
    component: MyStart,
    meta: {
      title: 'MyStart',
      noAuthRequired: true,
    },
  },
  {
    path: '/prueba',
    name: 'Prueba',
    component: Prueba,
    meta: {
      title: 'Prueba',
      noAuthRequired: true,
    },
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: UserLogin,
    meta: {
      title: 'Login',
      noAuthRequired: true,
    },
  },
  {
    path: '/register',
    name: 'UserRegister',
    component: UserRegister,
    meta: {
      title: 'Register',
      noAuthRequired: true,
    },
  },
  {
    path: '/perfil',
    name: 'UserPerfil',
    component: UserPerfil,
    meta: {
      title: 'Perfil',
    },
  },
  {
    path: '/recuperar',
    name: 'MyRetrieve',
    component: MyRetrieve,
    meta: {
      title: 'Retrieve',
      noAuthRequired: true,
    },
  },
  {
    path: '/recuperarclave',
    name: 'resetPassword',
    component: resetPassword,
    meta: {
      title: 'ResetPassword',
      noAuthRequired: true,
    },
  },
  {
    path: '/entradas',
    name: 'posts',
    component: MyPosts,
    meta: {
      title: 'Posts',
      noAuthRequired: true,
    },
  },
  {
    path: '/entrada/:id',
    name: 'singlepost',
    component: SinglePost,
    meta: {
      title: 'SinglePost',
      noAuthRequired: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
});

// Set SEO metatag
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

// Logged-in state check
router.beforeEach((to, from, next) => {
  // redirect to Login page if auth required but not logged in
  if (!to.meta.noAuthRequired && !store.state.isLoggedIn) {
    next({
      name: 'UserLogin',
      query: {
        redirectTo: to.name,
        //message: 'Please login to visit that page',
      },
    });
  }

  next();
});

export default router;

