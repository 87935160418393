<template>
    <div class="container margin">
        <div v-if="message" class="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>{{message}}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <div class="row d-flex justify-content-center">
            <div class="col-md-7">
                <div class="card px-5 py-4" id="">
                    <div class="form-data" >
                        <div class="text-center mb-4">
                            <h4>Recuperar clave</h4>
                        </div>
                        <div class="forms-inputs mb-4"> <span>Email</span> <input type="text" v-model="email" v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email) && emailBlured}" v-on:blur="emailBlured = true">
                            <div class="invalid-feedback">El campo es requerido.</div>
                        </div>
                        <div class="mb-3"> <button v-on:click.stop.prevent="submit" class="btn btns w-100">Registrar</button> </div>
                    </div>
                </div>
            </div>
        </div>
        <MyLoading v-if="isLoading" />
    </div>
</template>

<script>
import axios from 'axios';
import MyLoading from '@/components/Loading.vue';
export default {
    name: 'MyRetrieve',
    components: {
        MyLoading,
    },
    data() {
        return {
            email: '',
            message: '',
            isLoading: false,
            emailBlured : false,
            example : 'prueba',
        }
    },
    methods:{
        messages() {
            this.$emit('message', 'prueba')
        },
        validate : function(){
            this.emailBlured = true;
            if(this.validEmail(this.email)){
                this.valid = true;
            }
        },
        validEmail : function(email) {
            let valitateEmail = /(.+)@(.+){2,}\.(.+){2,}/;
            if(valitateEmail.test(email.toLowerCase())){
                return true;
            }
        },
        code () {
            this.message = '';
            this.isLoading = true;  
            const data = JSON.stringify({
                email : this.email
            })
            console.log(data)
            axios
            .post(`bdpwr/v1/reset-password/`, data, {
                headers :{
                    "Content-Type":"application/json",
                    "Accept" : "*/*"
                }
            })
            .then((response) => {
                // if redirected to login from secured page, redirect back
                console.log(response)
                this.$router.push({ name: 'resetPassword'});

            })
            .catch((error) => {
                this.message = error.response.data.message;
                this.isLoading = false;
            });

        },
        submit : function() {
            this.validate();
            if(this.valid) {
                this.code();
            }
        }
    },
}
</script>