<template>
  <div class="margin-bottom">
    <section class="hero">
      <div class="d-none d-lg-block">
        <img src="@/assets/Banner-escritorio.jpg" alt="">
      </div>
      <div class="d-none d-md-block d-lg-none">
        <img src="@/assets/Banner-Principal-Tablet.jpg" alt="">
      </div>
      <div class="d-block d-md-none">
        <img src="@/assets/Banner-Principal-Movil.jpg" alt="">
      </div>
      <div class="hero__content">
        <h1 class="fs-sm-2"> Con ValesyCupones <span class="text-greenlight">ganas dinero</span> mientras compras las mejores marcas</h1>
      </div>
    </section>

    <section class="ganaDinero container mt-5">
      <h2 class="fs-4 text-center w-100">¿Cómo funciona?</h2>
        <div class="row pt-4 m-0 justify-content-center">
          <div class="col-3 col-xl-4 text-center">
            <img src="@/assets/home/usuario-verde.svg" alt="" srcset="">
          </div>
          <div class="col-9 col-xl-4">
            <h3 class="fs-sm-3">1º Regístrate</h3>
            <p class="fs-4">para ahorrar en tus marcas favoritas y recibir notificaciones de nuevas promociones</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-3 col-xl-4 text-center">
            <img src="@/assets/home/mano-verde.svg" alt="" srcset="">
          </div>
          <div class="col-9 col-xl-4">
            <h3 class="fs-sm-3">2º Selecciona</h3>
            <p class="fs-4">todas las promociones que te gusten</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-3 col-xl-4 text-center">
            <img src="@/assets/home/ticket-verde.svg" alt="" srcset="">
          </div>
          <div class="col-9 col-xl-4">
            <h3 class="fs-sm-3">3º Disfruta ahorrando</h3>
          </div>
        </div>
    </section>
    <section class="cupones my-6">
      <h2 class="fs-sm-5 text-center">Cupones</h2>
      <MyCoupons />
    </section>
    <MyLoading v-if="isLoading" />
    <ModalPrimary/>
  </div>
</template>

<script>
import MyLoading from '@/components/Loading.vue';
import ModalPrimary from '@/components/modales/ModalPrimary.vue';
import MyCoupons from '@/components/Coupons.vue';
  export default {
    name: 'MyHome',
    components :{
      MyLoading,
      ModalPrimary,
      MyCoupons
    },
    data() {
      return {
        marcas : [],
        entradas : [],
        fontSize : '4rem',
        heightImage: '150px',
        flickityOptions: {
          freeScroll: true,
          contain: true,
          prevNextButtons: false,
          pageDots: false,
        },
        flickityOptionsPost: {
          freeScroll: true,
          contain: true,
          prevNextButtons: false,
          pageDots: false,
          initialIndex: 1,
          draggable: true,
          //wrapAround: true,
        },
        isLoading: false,
      };
    },

    created() {
      this.isLoading = true
      this.getMarcas()
      this.getPost()
      this.isLoading = false
    },

    methods : {

      async getMarcas() {
        const marcasDB = await this.axios.get('wp/v2/marca?_embed');
          await marcasDB.data.forEach(element => {
            let item = {}
            item.id = element.id;
            item.date = element.date;
            item.status = element.status;
            item.content = element.content.rendered;
            item.title = element.title.rendered;
            item.image = element._embedded['wp:featuredmedia']['0']['source_url']
            item.slug  = element.slug
            this.marcas.push(item)
          })
      },
      async getPost() {
        const entradasDB = await this.axios.get('wp/v2/posts?_embed');
        await entradasDB.data.forEach(element => {
            let item = {}
            item.id = element.id;
            item.date = element.date;
            item.status = element.status;
            item.content = element.content.rendered;
            item.title = element.title.rendered;
            item.excerpt = element.excerpt.rendered;
            if(element.featured_media != 0){
              item.image = element._embedded['wp:featuredmedia']['0']['source_url']
            }
            item.slug  = element.slug
            item.video = element.acf.video

            item.miniatura = element.acf.miniatura
            item.mobile = element.acf.mobile
            item.tablet = element.acf.tablet
            item.desktop = element.acf.desktop
      
            this.entradas.push(item)
          })
         
      }
    }
  }
</script>
<style lang="scss">
@import '@/scss/homeview.scss';
</style>
<!-- <style lang="scss">
  .carousel-cell {
    width: 250px;
    height: 250px;
    color: white;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .carousel-posts {
    width: 310px;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h2 {
    font-weight: bold;
  }

  .div__color {
      position: absolute;
      background-color: var(--greenlight);
      opacity: .3;
      height: 100%;
      width: 100%;
      z-index: 0;
      left: 0;
  }

  .hero {
    height: 70vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    
    text-align: center;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .hero__content {
      position: relative;
      z-index: 1;
      color: white;
      width: 50%;
      padding: 50px;
     
      h1 {
        font-weight: 300;
      }

      span {
        //color: var(--greenlight);
        font-weight: bold;
      }
      

      .a__button {
        background-color: white !important;
        height: 40px;
        color: black !important;
        font-weight: bold;
        width: 100%;
        border-radius: 50px;
      }
    }
  }

  .ganaDinero {
    h3 {
      color: var(--greenlight);
    }
  }

  .gana__tiempo {
    height: 70vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    
    text-align: center;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    .hero__content {
      position: relative;
      z-index: 1;
      color: black;
      width: 50%;
      padding: 50px;
     
      h1 {
        font-weight: 300;
      }

      p {
        font-size: 20px;
      }

      .parrafo {
        font-size: 16px;
      }

      span {
        color: var(--greenlight);
        font-weight: bold;
        font-style: italic ;
        font-size: 24px;
      }
      

      .a__button {
        background-color: white !important;
        height: 40px;
        color: black !important;
        font-weight: bold;
        width: 100%;
        border-radius: 50px;
      }
    }
  }

  .contentInteresantes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color:  #F1F1F1;

    .flickity-viewport{
      height: 500px !important;
    }


    .btn {
      color: black !important;
      border: 1px solid  var(--green);

    }

    .card-text {
      font-size: 13px;
      color: black;
    }

    .card-title {
      height: 85px;
    }

    h2 {
      color: var(--green);
    }
    h3 {
      color: var(--greenlight);
    }

    a {
      color: var(--greenlight);
      font-weight: bold;
      text-align: center;
      width: 100%;
    }

    .content {
      width: 80vw;
      height: 50vh;
      margin: 0 auto;
      z-index: 1;

      img {
        object-fit: cover;
      }

      p {
        font-weight: 700;
      }

      .div__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        height: 100%;
        //top: 50px;
      }
    }
  }

  .no__Renuncies{
    position: relative;
    text-align: center;
    width: 100%;

    .euro {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--greenlight);
      margin: 0 auto;
      font-size: 2rem;
    }

    h2 {
      font-style: italic;
    }

    .content__noRenuncies {
      p {
        color: var(--greenlight);
        font-weight: 600;
      }
    }

    a {
      background-color: var(--greenlight) !important;
      border-radius: 0.575rem;
      font-weight: 600;
      font-size: 24px;
    }

    .img__ganaDinero {
      position: relative;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      box-shadow: 1rem 1rem 0rem var(--greenlight) !important;
      overflow: hidden;
      width: 75%;
      border-radius: 0.375rem;

      p {
        font-weight: bold;
      }

      img {
        position: relative;
        z-index: 0;
      }

      .content__imgGana {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        z-index: 2;

      }
    }
  }

  .mx-post {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (max-width: 400px) {
    h1, h2 {
      font-size: 1.5rem !important;
    }

    .carousel-cell {
      width: 100px !important;
      height: 100px !important;
    }

    .hero {
      align-items:  center !important;
      justify-content: end !important;

      .hero__content {
        width: 100% !important;
        padding: 10px !important;
      }
      h1 {
        font-size: calc(1.375rem + 1.5vw);
      }
    }
  }

  @media (max-width: 991.98px) and (min-width: 401px) {

    .carousel-cell {
      width: 150px !important;
      height: 150px !important;
    }

    .hero {
      align-items:  center !important;
      justify-content: end !important;

      .hero__content {
        width: 100% !important;
        padding: 10px !important;
      }
      h1 {
        font-size: calc(1.375rem + 1.5vw);
      }
    }
    .a__button { 
      width: 100% !important;
     }


    .contentInteresantes .content .div__content {
      justify-content: end !important;
      width: 100%;
    }
  }

</style> -->