import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const mutations = {
  cacheUser(state, { token, email, displayName, idUser, firstName, lastName, consumerData, avatar}) {
    state.isLoggedIn = true;
    state.userToken = token;
    state.userEmail = email;
    state.userDisplayName = displayName;
    state.idUser = idUser;
    state.firstName = firstName;
    state.lastName = lastName;
    state.lastName = lastName;
    state.consumerData = consumerData;
    state.avatar = avatar;
  },

  deleteUserCache(state) {
    state.isLoggedIn = false;
    state.userToken = '';
    state.userEmail = '';
    state.userDisplayName = '';
    state.idUser = '';
    state.firstName = '';
    state.lastName = '';
    state.consumerData = '';
    state.avatar = '';

  },
};

const actions = {
  async login({ commit }, payload) {
    //const response = await axios.post(`jwt-auth/v1/token`, {
    const response = await axios.post(`consumer-data/v1/datos/`, {
      username: payload.username,
      password: payload.password,
    });

    console.log(response)

    const data = response.data;

    localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('idUser', data.data.id);
    localStorage.setItem('token', data.data.token);
    localStorage.setItem('displayName', data.data.displayName);
    localStorage.setItem('email', data.data.email);
    localStorage.setItem('firstName', data.data.firstName);
    localStorage.setItem('lastName', data.data.lastName);
    localStorage.setItem('consumerData', data.data.consumerData);
    localStorage.setItem('avatar', data.data.avatar);

    // call cacheUser() from mutations
    await commit('cacheUser', {
      token: data.data.token,
      email: data.data.email,
      displayName: data.data.displayName,
      idUser : data.data.id ,
      firstName : data.data.firstName ,
      lastName :  data.data.lastName,
      consumerData : data.data.consumerData,
      avatar : data.data.avatar
    });
  },

  async logout({ commit }) {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('displayName');
    localStorage.removeItem('idUser');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('consumerData');
    localStorage.removeItem('avatar');

    commit('deleteUserCache');
  },

  /**
   * Check if user if logged in
   */
  async checkLoginState({ commit }) {
    const token = localStorage.getItem('token');

    // if no token, empty the loggedIn cache
    if (!token) {
      await commit('deleteUserCache');
      return false;
    }

    // if has token, check if it's still valid
    try {
      await axios.post(
        `jwt-auth/v1/token/validate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      
      // if still valid, cache it again
      await commit('cacheUser', {
        token,
        email: localStorage.getItem('email'),
        displayName: localStorage.getItem('displayName'),
        idUser: localStorage.getItem('idUser'),
        firstName : localStorage.getItem('firstName') ,
        lastName : localStorage.getItem('lastName'),
        consumerData: localStorage.getItem('consumerData'),
        avatar: localStorage.getItem('avatar'),
      });

      return true;
    } catch (error) {
      localStorage.setItem('token', '');
      return false;
    }
  },
}


const store = {
  // This is global data, use mutations and actions to change this value.
  state: {
    isAdmin: false,
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    userToken: localStorage.getItem('token') || '',
    userEmail: localStorage.getItem('email') || '',
    userDisplayName: localStorage.getItem('displayName') || '',
    idUser: localStorage.getItem('idUser') || '',
    firstName: localStorage.getItem('firstName') || '',
    lastName: localStorage.getItem('lastName') || '',
    consumerData: localStorage.getItem('consumerData') || '',
    avatar: localStorage.getItem('avatar') || '',
  },

  mutations,
  actions,
  modules: {
  },
};

export default new Vuex.Store(store);
