<template >
  <div> 
      <allPosts></allPosts>
  </div>
</template>

<script>
  import allPosts from '@/components/Posts.vue'
  export default {
    name: 'MyPrueba',
    components :  {
        allPosts,        
    },

  }
</script>
<style lang="scss">
  button.page-link {
    display: inline-block;
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }


  .offset{
    width: 500px !important;
    margin: 20px auto;  
  }
  .contentInteresantesPosts {
    position: relative;

    h2 {
      color: var(--greenOpacityLight);
    }

    .i-1 {
      left: 25px;
      top: 50px;
    }

    .i-2 {
      top: 20px;
      right: 25px;
    }

    a {
      color: var(--greenlight);
      font-weight: bold;
      text-align: center;
      width: 100%;
    }

    // img {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   object-fit: cover;
    //   z-index: 0;
    // }

    .content {
      height: 30vh;
      margin: 0 auto;
      z-index: 1;
      overflow: hidden;
      padding: 0;
      color: black !important;

      img {
        object-fit: cover;
        z-index: 0;
      }

      p {
        font-weight: 700;
      }

      // .after {
      //   z-index: 1;
      //     &::after {
      //     content: '';
      //     position: absolute;
      //     background-color: var(--greenlight);
      //     opacity: 0.3;
      //     height: 100%;
      //     width: 100%;
      //     z-index: 0;
      //     left: 0;
      //   }
      // }

      .div__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        top: 50px;
      }

      a {
        color: black;
      }
    }
  }

</style>