<template>
  <div>

    <div id="app" style="min-height: calc(100vh - 13.5rem);">
      <MySidebar />
        <router-view/>
        <MyLoading v-if="isLoading" />
      </div>
      <MyFooter />
  </div>
</template>

<script>
  import MyFooter from './components/MyFooter.vue';
  import MyLoading from '@/components/Loading.vue';
  import MySidebar from '@/components/MySidebar.vue';
  export default {
    name: 'App',

    components : {
      MyFooter,
      MyLoading,
      MySidebar
    },

    data() {
      return {
        isLoading: false,
      };
    },
    // check if auth token is still valid
    async created() {
      // only check valid if coming from pages that require auth
      if (this.$route.meta.noAuthRequired) {
        return;
      }

      const isValid = await this.$store.dispatch('checkLoginState');

      if (!isValid) {
        this.$router.push({
          name: 'UserLogin',
          query: {
            redirectTo: this.$route.name,
          },
        });
      }
    },

    methods: {}
  };
</script>