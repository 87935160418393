<template>
  <myLogin></myLogin>      
</template>
  
  <script>
  import myLogin from '@/components/Login.vue'
  export default {
    name: 'UserLogin',
    components: {
        myLogin
    },
    data() {
      return {
        
      };
    },
  }
  </script>