<template>
    <div class="container margin ">
        <section class="contentInteresantesPosts mt-5" v-if="posts && posts.length > 0">
            <div class="row row-cols-1 row-cols-sm-2  row-cols-lg-4 text-center justify-content-center">
                <div class="col mt-3" v-for="post in posts" :key="post.id">
                    <div class="card rounded border " >
                        <div v-if="post.acf.miniatura " class="w-100">
                            <img  :src="post.acf.miniatura" class="rounded-top w-100 object-fit-cover" :style="{height: heightImage}" alt="...">
                        </div>
                        <div v-else class="w-100">
                            <img  src="@/assets/gray.jpg" class="rounded-top w-100 object-fit-cover" :style="{height: heightImage}" alt="...">
                        </div>
                        <div class="card-body">
                            <h5 class="card-title text-black" v-html="post.title.rendered"></h5>
                            <p class="card-text" v-html="post.excerpt.rendered.substring(0,200)+'...'" ></p>
                            
                                <router-link :to="{path: 'entrada/' + post.id}" class="btn border rounded-pill text-black">Leer más</router-link>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="mt-5 text-center">
            <button role="button" class="btn mt-3 shadow-sm a__button text-black" @click.prevent="getMorePosts" v-if="isMorePages" >
                Más entradas
            </button>
        </div>
        <MyLoading v-if="isLoading" />
    </div>
</template>

<script>
import MyLoading from '@/components/Loading.vue';
export default {
    name:'allPosts',
    components: {
        MyLoading,
    },
    data() {
        return {
            posts: [],
            embed: true,  
            status:'publish',
            page: 1,
            fontSize: '4rem',
            isLoading: false,
            per_page: 10,
            numOffset: 0,
            totalPosts: 0,
            heightImage: '150px',

        }
    },
    created() {
        this.isLoading = true; 
            this.axios.get(`wp/v2/posts?_embed&per_page=${this.per_page}&offset=${this.numOffset}`)
            .then(response => {
                console.log(response.data)
                this.posts = response.data;
                this.totalPosts = response.headers['x-wp-total'];
            })
            .finally(() =>
                this.isLoading = false
            );
    },
    computed: {
        isMorePages: function() {
            return  this.page * this.per_page / this.totalPosts < 1;
        },
    },
    methods: {
        getMorePosts: function()  {
            this.isLoading = true; 
            this.page += 1;
            this.numOffset += this.per_page;
            this.axios.get(`wp/v2/posts?_embed&per_page=${this.per_page}&offset=${this.numOffset}`)
            .then((response) => {
                this.posts = this.posts.concat(response.data);
            })
            .finally(() =>
                this.isLoading = false
            );
        },

    }

}
</script>

<style lang="scss">
    .a__button {
        background-color: var(--greenlight) !important;
        height: 40px;
        color: black !important;
        font-weight: bold;
        width: 100%;
    }

    .card-button {
        position: absolute;
        width: 90%;
        bottom: 15px;
    }
</style>