<template>
  <div>
    <!-- form to update user meta data -->
    <form>
      <div class="forms-inputs mb-4">
                            <span>Photo</span> 
                            <input type="file" v-bind:class="{'form-control':true}" @change="handlePhotoUpload" />
                        </div>
      <button @click.prevent="onUpdateClicked()">Update Meta</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import store from '@/store';
  export default {
    data() {
      return {
        metaKey : 'profile_photo_url',
        metavalue: '',
        photo : null,
        config:{
          headers: {
              Authorization: 
              `Bearer ${store.state.userToken}`,
              'Content-Type': "multipart/form-data",
          }
        }
      }
    },
    methods: {
      async handlePhotoUpload(event) {
                this.photo = event.target.files[0];
            },

      onUpdateClicked() {
        // make an API call to the WordPress REST API to update user meta data
        const userId = 66; // or set the user ID as per your requirement
        const meta = JSON.stringify({
                    [this.metaKey]: this.photo
                });
        axios
        .post(`wp/v2/users/${userId}`, meta, this.config)
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }
</script>