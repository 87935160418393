<template>
  <div>
    <div class="modal-vue" v-if="showModal">
       <!-- overlay -->
       <div class="overlay" @click="showModal= false , isModalVisible = true"></div>
       
       <!-- modal -->
       <div class="modal-primary rounded overflow-hidden py-2 px-3 mx-3">
        <div class="d-flex justify-content-end align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" @click="showModal = false, isModalVisible = true" :style="{width: width}" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
  
        </div>
         <div class="p-3 text-center">
            <img src="@/assets/home/modal-img.png" alt="">
            <h2 class="my-2 fw-bold">Bienvenido al nuevo</h2>
            <h2 class=" fw-bold">Vales y Cupones</h2>
            <p class="fw-bold mb-0" :style="{color: secundary}">Nuevos diseños y promociones</p>
            <p class="mt-0" :style="{color: primary}"> Ahorra dinero mientras compras con las mejores marcas</p>
            <a  class="btn rounded-pill"  @click="isModalFather = true, showModal = false"> Comenzar</a>
         </div>
       </div>
    </div>
      <MyModal v-if="isModalFather"/>
  </div>
</template>

<script>
  import MyModal from '@/components/modales/MyModal.vue';
  export default {
    name : 'ModalPrimary',
    components : {
      MyModal,
    },
    data () {
        return {
          fontSize : '1.5rem',
          showModal: true,
          width: '20px',
          primary : '#224B50',
          secundary : '#00CCCC',
          //isModalVisible : false,
          isModalFather : false
        }
    }
}
</script>

<style lang="scss">
.modal-vue {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  
}

.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
}

.modal-vue .modal-primary {
  position: relative;
  z-index: 9999;
  background-color: #fff;
  width: 80%;
  animation:  fadeIn ease-in;

  h2 {
    color: #224B50;
    font-size: 1.25rem;
  }

  .font-p {
    font-size: 1.25rem;
    font-weight: bold;
  }

  
  .btn {
    color: white;
    text-align: center;
    background-color: #224B50 !important;
    width: 100%;
    animation: fadeIn 0.8s;
  }

  img {
    width: 100%;
  }
}

.modal-vue .close{
  position: absolute;
  top: 10px;
  right: 10px;
  animation: fadeOut 3s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@media (min-width: 768px) and (max-width: 1365px) {
  .modal-primary {
    width: 40% !important;
  }
}

@media (min-width: 1366px) {
  .modal-primary {
    width: 20% !important;
  }
}
</style>