<template>
    <div class="container margin">
      <div v-show="seen" class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>{{message}}</strong>
          <button type="button" class="btn-close"  v-on:click="seen = !seen"></button>
      </div>
  
      <div class="row d-flex justify-content-center">
        <div class="col-md-12 ">
          <div class="card  py-4" id="form1">
              <div class="form-data" >
                <div class="text-center mb-4">
                      <h4>Login</h4>
                </div>
                <form class="form" @submit.prevent="login">
                  <div class="forms-inputs mb-4"> <span>Username</span> <input type="text" v-model.trim="username" v-bind:class="{'form-control':true, 'is-invalid' : !validUsername(username) && usernameBlured}" v-on:blur="usernameBlured = true">
                      <div class="invalid-feedback">El campo es requerido.</div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Clave</span> <input type="password" v-model.trim="password" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(password) && passwordBlured}" v-on:blur="passwordBlured = true">
                      <div class="invalid-feedback">El campo es requerido.</div>
                  </div>
                  <div class="mb-3"> <button v-on:click.stop.prevent="submit" class="btn btns w-100">Login</button></div>
                </form>
                <div class="text-center">
                  <router-link to="/recuperar" class="retrieve">¿Ólvido su Contraseña?</router-link>
                </div>
                <div class="text-center">
                  <router-link to="/register" class="retrieve">Regístrate</router-link>
                </div>
              </div>
          </div>
        </div>
      </div>
          <MyLoading v-if="isLoading" />
    </div>
</template>
<script>
import MyLoading from '@/components/Loading.vue';

export default {
  name: 'myLogin',
  components: {
      MyLoading,
  },
  data() {
    return {
      username: '',
      usernameBlured:false,
      password: '',
      passwordBlured:false,
      message: '',
      isLoading: false,
      seen: false,
      valid : false,
      submitted: false
    };
  },

  methods: {

    validate : function(){
      this.passwordBlured = true;
      this.usernameBlured = true;
      if(this.validPassword(this.password) && this.validUsername(this.username)){
        this.valid = true;
      }
    },

    validPassword : function(password) {
      if (password.length > 0) {
        return true;
      }
    },

    validUsername : function(username) {
      if (username.length > 0 ) {
        return true;
      }
    },

    async login() {
      this.message = '';
      this.isLoading = true;  
      try {
        // Call the login function in store.js
        await this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
        });

        this.isLoading = true;
        console.log(window.location)
        // if redirected to login from secured page, redirect back
        if (this.$route.query.redirectTo) {
          this.$router.push({ name: this.$route.query.redirectTo });
        }
        // else redirect to Home
        else {
          if(window.location.pathname == "/"){
            window.location.replace("/")
          }else{
            window.location.replace("/")
            //this.$router.push({ name: 'home' });
          }
        }
      } catch (error) {
        console.log(error.response.data.message)
        this.isLoading = false;
        this.message = error.response.data.message
        this.seen = true
      }
    },

    submit : function() {
    this.validate();
      if(this.valid) {
        this.submitted = true;
        this.login();
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/scss/form';

  .retrieve{
    color: var(--greenlight);
  }
</style>