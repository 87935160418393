<template>
    <div>
        <section class="hero">
            <div v-if="desktop || tablet || mobile">
                <div class="d-none d-lg-block">
                    <img :src="desktop" class="w-100 img-blog  object-fit-cover" alt="">
                </div>
                <div class="d-none d-md-block d-lg-none">
                    <img :src="tablet" class="w-100 img-blog object-fit-cover" alt="">
                </div>
                <div class="d-block d-md-none ">
                    <img :src="mobile" class="w-100 img-blog  object-fit-cover" alt="">
                </div>
            </div>
            <div v-else>
                <img  src="@/assets/gray.jpg" class="rounded-top w-100 object-fit-cover" alt="...">
            </div>

            <div class="hero__content text-black">
                <h1>{{ title }}</h1>
                <!-- <span>{{ date }}</span> -->
            </div>
        </section>
        <div class="container margin">
            <section class="content" v-html="content">
            </section>
            <section class="video embed-responsive" v-html="video">
            </section>
        </div>
        <MyLoading v-if="isLoading" />
    </div>

</template>
<script>
import MyLoading from '@/components/Loading.vue';
export default {
    name: 'MyPost',
    components: {
        MyLoading,
    },

    data() {
        return {
            id : this.$route.params.id,
            isLoading : false,
            title: '',
            content: '',
            image: '',
            date: '',
            video: '',
            legacyAttrs: false,
            miniatura: '',
            desktop: '',
            tablet: '',
            mobile: '',

        }
    },
    created() {
        this.initialize();
    },
    methods: {
        play() {
            console.log('play callback')
        },

        async initialize() {
            this.isLoading = true;  
            try {
                const data = await this.axios.get( `wp/v2/posts/${this.id}?_embed`);
                console.log(data.data.acf)
                this.title = data.data.title.rendered
                this.content = data.data.content.rendered
                if(data.data.featured_media != 0){
                    this.image = data.data._embedded['wp:featuredmedia'][0]['source_url']
                }
                this.date = data.data.date
                this.video = data.data.acf.video
                this.miniatura = data.data.acf.miniatura
                this.desktop = data.data.acf.desktop
                this.tablet = data.data.acf.tablet
                this.mobile = data.data.acf.mobile
            } catch (error) {
                console.log(error);
            }
            this.isLoading = false;  
        },
    }
}
</script>
<style lang="scss">
.content {
    text-align: justify;
}

.embed-responsive{
    iframe {
        width: 100%;
        height: 40vh;
    }
}
</style>