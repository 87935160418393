<template>
    <footer class="footer d-flex flex-column flex-md-row justify-content-between ">
        <router-link to="/" class="mb-4 mb-md-0">
            <img src="@/assets/logo.png" width="100" alt="">
        </router-link>
        <div class=" flex-column flex-md-row d-flex justify-content-center text-center justify-content-md-evenly gap-3">
                <a :href="pp" v-text="'Política de Privacidad'"/>
                <a :href="av" v-text="'Aviso Legales'"/>
                <a :href="cookies" v-text="'Cookies'"/>
                <a :href="cm" v-text="'Consultoria Manual'"/>
        </div>
    </footer>
</template>
<script>
export default {
    name : 'MyFooter',
    data () {
        return {
            fontSize : '1.5rem',
            pp : 'documents/POLITICA_DE_PRIVACIDAD_SAVI_1.pdf',
            av : 'documents/AVISO_LEGAL_SAVI.pdf',
            cookies: 'documents/COOKIES.pdf',
            cm : 'documents/CONSULTORIA-MANUAL.pdf',
        }
    }
}
</script>
<style lang="scss">
.footer {
    background-color: var(--green);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1em;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
}
@media screen 
and (max-width : 425px) {
    .footer {
        position: relative;
    }
}
</style>