<template>
    <div class="container margin">
        <div v-if="message" class="alert alert-dismissible fade show" :class="colorMessage" role="alert">
            <strong>{{message}}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="limpiar"></button>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-md-7">
                <div class="card px-5 py-4" id="">
                    <div class="img-profile d-flex justify-content-center mb-1" v-if="$store.state.avatar != ''">
                        <img :src="avatar" class="object-fit-cover rounded-circle" width="60px" height="60px"  alt="">
                    </div>
                    <form autocomplete="off" class="form-data" >
                        <div class="text-center mb-4">
                            <h4>Perfil {{first_name}}</h4>
                        </div>
                        <div class="forms-inputs mb-4">
                            <span>Photo</span> 
                            <input type="file" v-bind:class="{'form-control':true}" @change="handlePhotoUpload" />
                        </div>
                        <div class="forms-inputs  mb-4"> <span>Nombres</span> <input type="text" class="form-control" v-model="nombre" autocomplete="off">
                        </div>
                        <div class="forms-inputs mb-4"> <span>Apellidos</span> <input type="text" class="form-control" v-model="apellido" autocomplete="off">
                        </div>
                        <!-- <div class="forms-inputs mb-4"> <span>Descripción</span> <input type="text" class="form-control" v-model="descripcion" autocomplete="off">
                        </div> -->
                        <div class="forms-inputs mb-4"> 
                            <span>Password</span> 
                            <input type="password" v-model="password" class="form-control" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(password) && passwordBlured}" v-on:blur="passwordBlured = true" autocomplete="off">
                            <div class="invalid-feedback">
                                <ul>
                                <li>El campo Requerido.</li>
                                <li>Mínimo 8 caracteres maximo 15.</li>
                                <li>Debe de tener al menos una letra en minúscula.</li>
                                <li>Debe de tener al menos una letra en mayúscula.</li>
                                <li>Debe de tener al menos un número.</li>
                                <li>Debe de tener al menos un caracteres especiales (!@#$%^&*).</li>
                                </ul>
                            </div>
                        </div>
                        <div class="forms-inputs mb-4"> <span>Repita la Contraseña</span> <input type="password" v-model.trim="repeatPassword" v-bind:class="{'form-control':true, 'is-invalid' : !validRepeatPassword(repeatPassword) && passwordBlured}" v-on:blur="repeatPasswordBlured = true" autocomplete="off">
                            <div class="invalid-feedback">Las Contraseña deben de ser iguales.</div>
                        </div>
                        <div class="mb-3"> 
                            <button v-on:click.stop.prevent="submit" class="btn btns w-100">Actualizar</button> 
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <MyLoading v-if="isLoading" />
  </div>

    
</template>

<script>
    import store from '@/store';
    import axios from 'axios';
    import MyLoading from '@/components/Loading.vue';

    export default {
        name: 'UserPerfil',
        components: {
            MyLoading,
        },
        data() {
            return {
                message: '',
                colorMessage: '',
                isLoading: false,  
                valid: false,
                first_name: store.state.userDisplayName,
                id : store.state.idUser,
                nombre: store.state.firstName,
                apellido: store.state.lastName,
                avatar : store.state.avatar,
                photo : null,
                metaKey : 'profile_photo_url',
                descripcion: '',
                password: '',
                passwordBlured:false,
                repeatPassword: '',
                repeatPasswordBlured: 'false',
                config:{
                    headers: {
                        Authorization: 
                        `Bearer ${store.state.userToken}`,
                        'Content-Type': "multipart/form-data",
                    }
                }
            }
        },

        created() {
            this.initialize()
        },
        methods:{
            limpiar() {
                this.message = ''
            },

            validate : function(){
                this.passwordBlured = true;
                this.repeatPasswordBlured = true
                if(  this.validPassword(this.password)  && this.validRepeatPassword(this.repeatPassword)){
                    this.valid = true;
                }
            },

            validPassword : function(password) {
                let pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
                if(password === ''){
                    return true;
                }else if(pattern.test(password)) 
                    return true;
            },

            validRepeatPassword : function(repeatPassword) {
                if(repeatPassword == this.password){
                    return true;
                }
            },

            async initialize() {
                this.isLoading = true;  
                try {
                    const data = await this.axios.get( `wp/v2/users/${this.id}?_embed`, this.config);
                    console.log(data)
                    this.descripcion = data.data.description
                } catch (error) {
                    console.log(error);
                }
                this.isLoading = false;  

            },
            async logout() {
                await this.$store.dispatch('logout');
                this.$router.push({ name: 'UserLogin' });
            },

            async handlePhotoUpload(event) {
                this.photo = event.target.files[0];
            },

            Actualizar() {
                this.message = '';
                this.isLoading = true; 
                const formData = new FormData();
                formData.append("first_name", this.first_name);
                formData.append("last_name", this.apellido);
                formData.append("password", this.password);
                if(this.password == '') {
                    delete formData.delete('password');
                }
                axios
                .post(`wp/v2/users/${this.id}`, formData, this.config)
                .then((response) => {
                    const updatedUser = response.data;
                    localStorage.setItem('firstName', updatedUser['first_name']);
                    localStorage.setItem('lastName', updatedUser['last_name']);
                    this.nombre = updatedUser.first_name;
                    this.apellido = updatedUser.last_name;

                    // if redirected to login from secured page, redirect back
                    if(this.password != '') {
                        this.logout()
                        this.$router.push('Login');
                    }
                    this.isLoading = false;
                    this.colorMessage = "alert-success"
                    this.message = "Actualización Correcta de los datos.";
                })
                .catch((error) => {
                    console.log(error)
                    this.colorMessage = "alert-warning"
                    this.message = error.response.data.message;
                    this.isLoading = false;
                });
            },

            UpdatePhoto() {

                const formData = new FormData();
                formData.append("user_id", this.id);
                formData.append("file", this.photo);

                axios
                .post(`wp/v2/updatemeta/photo`, formData , this.config)
                .then((response) => {
                    localStorage.setItem('avatar', response['data']['profile_photo_url']);
                    console.log(store.state.avatar)
                    window.location.replace("/UserPerfil")
                    // this.logout()
                    // this.$router.push('Login');
                    // if redirected to login from secured page, redirect back
                
                })
                .catch((error) => {
                    console.log(error)
                   
                });
                
            },
            submit : function() {
                this.validate();
                if(this.valid) {
                    if(this.photo != null) {
                        this.UpdatePhoto();
                    }
                    this.Actualizar();
                }
            }

        }
    }
</script>

<style lang="scss">
  @import '@/scss/form';
  .userPerfil{
    margin-top: 4rem;
  }
</style>