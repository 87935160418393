<template>
    <div class="margin-bottom">
      <section class="hero">
        <div class="d-none d-lg-block">
          <img src="@/assets/Banner-escritorio.jpg" alt="">
        </div>
        <div class="d-none d-md-block d-lg-none">
          <img src="@/assets/Banner-Principal-Tablet.jpg" alt="">
        </div>
        <div class="d-block d-md-none">
          <img src="@/assets/Banner-Principal-Movil.jpg" alt="">
        </div>
        <div class="hero__content mx-4">
          <h1 class="fs-sm-2"> Con <span class="fw-bold">ValesyCupones</span> <span class="text-greenlight">ganas dinero</span> mientras compras las mejores marcas</h1>
        </div>
      </section>
  
      <section class="ganaDinero container mt-5 ">
        <h2 class="fs-sm-2 text-center w-100">¿Cómo funciona?</h2>
          <div class="row pt-4 m-0 justify-content-center">
            <div class="col-3 col-xl-4 text-center">
              <img src="@/assets/home/usuario-verde.svg" alt="" srcset="">
            </div>
            <div class="col-9 col-xl-4">
              <h3 class="fs-sm-2 fw-bold">1º Regístrate</h3>
              <p class="fs-sm">para ahorrar en tus marcas favoritas y recibir notificaciones de nuevas promociones</p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-3 col-xl-4 text-center">
              <img src="@/assets/home/mano-verde.svg" alt="" srcset="">
            </div>
            <div class="col-9 col-xl-4">
              <h3 class="fs-sm-2 fw-bold">2º Selecciona</h3>
              <p class="fs-sm">todas las promociones que te gusten</p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-3 col-xl-4 text-center">
              <img src="@/assets/home/ticket-verde.svg" alt="" srcset="">
            </div>
            <div class="col-9 col-xl-4">
              <h3 class="fs-sm-2 fw-bold">3º Disfruta ahorrando</h3>
            </div>
          </div>
      </section>
      <section class="gana__tiempo mt-5  position-relative">
        <div class="content-image">
          <div class="d-none d-lg-block">
            <img src="@/assets/home/01-Banner2-escritorio-.jpg" alt="">
          </div>
          <div class="d-none d-md-block d-lg-none">
            <img src="@/assets/home/02-Banner2-Tablet-.jpg" alt="">
          </div>
          <div class="d-block d-md-none">
            <img src="@/assets/home/03-Banner2-Movil-.jpg" class="w-100" alt="">
          </div>
        </div>
         
        <div class="hero__content">
          <h3 class="fs-sm-2">Gana dinero, <span class="fs-1 text-greenlight">Gana tiempo</span></h3>
          <p class="fs-md">Encuentra de forma rápida y fácil descuentos para tu cesta de la compra</p>
        </div>
      </section>
  
      <section class="marcas mt-5 container position-relative h-full" v-if="marcas && marcas.length > 0">
        <h2 class="fs-sm-2 text-center" style="color: #224B50;">Las mejores marcas</h2>
        <flickity ref="flickity" :options="flickityOptions" class="mt-1">
          <div class="carousel-cell" v-for="marca in marcas" :key="marca.id">
            <img :src="marca.image" class="w-100" :alt="marca.slug">
          </div>
        </flickity>
      </section>
  
  
  
      <section class="contentInteresantes  py-5" v-if="entradas && entradas.length > 0">
        <h2 class="fs-sm-2 text-center">Tips para tener una mejor</h2>
        <h3 class="text-center fs-sm-2">vida ahorrando</h3>
        <flickity ref="flickity" :options="flickityOptionsPost" class="mt-5">
          <div v-for="entrada in entradas" :key="entrada.id">
            
              <div class="card rounded mx-post carousel-posts overflow-hidden">
                <div v-if="entrada.miniatura" class="w-100">
                  <img  :src="entrada.miniatura" class="rounded-top w-100 object-fit-cover" :style="{height: heightImage}" alt="...">
                </div>
                <div v-else class="w-100">
                  <img  src="@/assets/gray.jpg" class="rounded-top w-100 object-fit-cover" :style="{height: heightImage}" alt="...">
                </div>
                <div class="card-body">
                  <h5 class="card-title text-black" v-html="entrada.title"></h5>
                  <p class="card-text" v-html="entrada.excerpt.substring(0,200)+'...'"></p>
                  <router-link :to="{path: 'entrada/' + entrada.id}" class="btn rounded-pill text-black">Leer más</router-link>
                </div>
              </div>
            
          </div>
        </flickity>
      </section>
  
      <div class="" style="position: relative;">
        <section class="no__Renuncies mt-3 " style="margin-bottom: 5rem;">
          <div class="content__noRenuncies mt-3">
            <h2 class="fs-sm-2">No Renuncies a nada</h2>
            <h2 class="fs-sm-2 text-greenlight">Ahorra dinero <span class="text-black">y</span> disfruta de tu tiempo</h2>
          </div>
  
          <div class="my-5" v-if="!$store.state.isLoggedIn">
            <router-link class="btn rounded-pill text-white w-75" to="/register">Regístrate gratis</router-link>
          </div>
        </section>
      </div>
      <MyLoading v-if="isLoading" />
    </div>
  </template>
  
  <script>
  import MyLoading from '@/components/Loading.vue';
  import Flickity from 'vue-flickity';
    export default {
      name: 'MyStart',
      components :{
        Flickity,
        MyLoading,
      },
      data() {
        return {
          marcas : [],
          entradas : [],
          fontSize : '4rem',
          heightImage: '150px',
          flickityOptions: {
            freeScroll: true,
            contain: true,
            prevNextButtons: false,
            pageDots: false,
          },
          flickityOptionsPost: {
            freeScroll: true,
            contain: true,
            prevNextButtons: false,
            pageDots: false,
            initialIndex: 1,
            draggable: true,
            //wrapAround: true,
          },
          isLoading: false,
        };
      },
  
      created() {
        this.isLoading = true
        this.getMarcas()
        this.getPost()
        this.isLoading = false
      },
  
      methods : {
  
        async getMarcas() {
          const marcasDB = await this.axios.get('wp/v2/marca?_embed');
            await marcasDB.data.forEach(element => {
              let item = {}
              item.id = element.id;
              item.date = element.date;
              item.status = element.status;
              item.content = element.content.rendered;
              item.title = element.title.rendered;
              item.image = element._embedded['wp:featuredmedia']['0']['source_url']
              item.slug  = element.slug
              this.marcas.push(item)
            })
        },
        async getPost() {
          const entradasDB = await this.axios.get('wp/v2/posts?_embed');
          await entradasDB.data.forEach(element => {
              let item = {}
              item.id = element.id;
              item.date = element.date;
              item.status = element.status;
              item.content = element.content.rendered;
              item.title = element.title.rendered;
              item.excerpt = element.excerpt.rendered;
              if(element.featured_media != 0){
                item.image = element._embedded['wp:featuredmedia']['0']['source_url']
              }
              item.slug  = element.slug
              item.video = element.acf.video
  
              item.miniatura = element.acf.miniatura
              item.mobile = element.acf.mobile
              item.tablet = element.acf.tablet
              item.desktop = element.acf.desktop
        
              this.entradas.push(item)
            })
           
        }
      }
    }
  </script>
  <style lang="scss">
    @import '@/scss/homeview.scss';
  </style>