import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.css"

import Flickity from 'vue-flickity';
Vue.use(Flickity, Flickity)

import Embed from 'v-video-embed'
Vue.use(Embed);

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

/// axios config 
import axios from 'axios'
import VueAxios from 'vue-axios'
 Vue.use(VueAxios, axios)
axios.defaults.baseURL = 'https://backend.valesycupones.es/wp-json/';
//axios.defaults.baseURL = 'http://backvalesycupones.test/wp-json/';

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
Vue.use(VueSidebarMenu)


import "@/scss/_global.scss"

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


window.getConsumerData = function(loginRequired,setConsumerData){
  let encryptedConsumerData = '';
  let gallerySetConsumerData;
  gallerySetConsumerData = setConsumerData;
  if (loginRequired) {
    // data is required
    if(!encryptedConsumerData || !encryptedConsumerData.length) {
        encryptedConsumerData = document.getElementById('modal-login')
        encryptedConsumerData.classList.remove("d-none")
        document.querySelector('.modal-overlay-login').classList.remove("d-none")
    }
  }
  encryptedConsumerData =  store.state.consumerData
  if(encryptedConsumerData){
      gallerySetConsumerData(encryptedConsumerData);
  }
}

import "bootstrap/dist/js/bootstrap.js"
