<template>
<div class="container margin">
  <div v-if="message" class="alert alert-dismissible fade show" :class="colorMessage" role="alert">
    <strong >{{message}}</strong>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
<div v-else class="alert alert-dismissible fade show" :class="colorMessage" role="alert">
    <strong >{{message}}</strong>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>

  <div class="row d-flex justify-content-center">
      <div class="col-md-7">
          <div class="card px-5 py-4" id="">
              <div class="form-data" >
                  <div class="text-center mb-4">
                      <h4>Cambio de Clave</h4>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Email</span> <input type="text" v-model="email" v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email) && emailBlured}" v-on:blur="emailBlured = true">
                      <div class="invalid-feedback">El campo es requerido.</div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Código</span> <input type="text" v-model="code" v-bind:class="{'form-control':true, 'is-invalid' : !validCode(code) && codeBlured}" v-on:blur="codeBlured = true">
                      <div class="invalid-feedback">El campo es requerido.</div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Contraseña</span> <input type="password" v-model="password" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(password) && passwordBlured}" v-on:blur="passwordBlured = true">
                    <div class="invalid-feedback">
                        <ul>
                          <li>El campo Requerido.</li>
                          <li>Mínimo 8 caracteres maximo 15.</li>
                          <li>Debe de tener al menos una letra en minúscula.</li>
                          <li>Debe de tener al menos una letra en mayúscula.</li>
                          <li>Debe de tener al menos un número.</li>
                          <li>Debe de tener al menos un caracteres especiales (!@#$%^&*).</li>
                        </ul>
                      </div>
                  </div>
                  <div class="forms-inputs mb-4"> <span>Repita la Contraseña</span> <input type="password" v-model.trim="repeatPassword" v-bind:class="{'form-control':true, 'is-invalid' : !validRepeatPassword(repeatPassword) && passwordBlured}" v-on:blur="repeatPasswordBlured = true">
                    <div class="invalid-feedback">Las Contraseña deben de ser iguales.</div>
                  </div>
                  <div class="mb-3"> <button v-on:click.stop.prevent="submit" class="btn btns w-100">Enviar</button> </div>
              </div>
          </div>
      </div>
  </div>
  <MyLoading v-if="isLoading" />
</div>
</template>
<script>
import axios from 'axios';
import MyLoading from '@/components/Loading.vue';
export default {
    name: 'resetPassword',
    components: {
        MyLoading,
    },
    data() {
        return {
            email : "",
            emailBlured : false,
            valid : false,
            submitted : false,
            password:"",
            passwordBlured:false,
            code:"",
            codeBlured:false,
            message: 'Se ha enviado un correo, por favor consultar.',
            nombres: '',
            apellidos: '',
            isLoading: false,
            repeatPassword: '',
            repeatPasswordBlured: 'false',
            colorMessage: 'alert-success',
        }
    },

    methods : {
        validate : function(){
            this.emailBlured = true;
            this.passwordBlured = true;
            this.codeBlured = true;
            this.repeatPasswordBlured = true;
            if( this.validEmail(this.email) && this.validPassword(this.password) && this.validCode(this.code) && this.validRepeatPassword(this.repeatPassword)){
                this.valid = true;
            }
        },

        validEmail : function(email) {
            let valitateEmail = /(.+)@(.+){2,}\.(.+){2,}/;
            if(valitateEmail.test(email.toLowerCase())){
                return true;
            }
        },

        validPassword : function(password) {
            let pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;
            if (pattern.test(password)) {
                return true;
            }
        },

        validRepeatPassword : function(repeatPassword) {
            if(repeatPassword == this.password){
                return true;
            }
        },

        validCode : function(code) {
            if (code.length > 0 ) {
                return true;
            }
        },

        resetPassword(){
            this.message = '';
            this.isLoading = true;
            const data = JSON.stringify({
                email : this.email,
                code: this.code,
                password: this.password
            })
            axios
            .post(`bdpwr/v1/set-password/`, data, {
                headers :{
                    "Content-Type":"application/json",
                    "Accept" : "*/*"
                }
            })
            .then((response) => {
                // if redirected to login from secured page, redirect back
                this.$router.push('UserLogin');
                console.log(response);
                this.isLoading = false;
            })
            .catch((error) => {
                console.log(error)
                this.colorMessage = "alert-warning"
                this.message = error.response.data.message;
                this.isLoading = false;
            });

        },

        submit : function() {
            this.validate();
            if(this.valid) {
                this.submitted = true;
                this.resetPassword();
            }
        }

    }
}

</script>
